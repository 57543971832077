<template>
  <div v-if="StoreDevice && StoreFrontendSettings" class="app" :style="f_calculateAppContainerStyle()">
    <!-- top menu mode -->
      <div v-if="StoreFrontendSettings && ['1'].indexOf(StoreFrontendSettings.data.menu_type) !== -1 && !StoreDevice.isMobile && d_showAplicationHeader" :style="f_navBarTopMenuStyle()">
        <b-row :style="f_calculateNavbarStyle()">
          <b-col cols="6">
            <div v-if="d_allFlowingText.list.length > 0 && d_allFlowingText.text" style="white-space: nowrap; padding-top: 5px;">
              {{ d_allFlowingText.text.substring(d_allFlowingText.text.length - d_allFlowingText.index, d_allFlowingText.text.length) }}
              <!-- {{ d_allFlowingText.text.length }} -->
              <!-- {{ d_allFlowingText.index }} -->
            </div>
          </b-col>
          <b-col cols="6" style="text-align: right;">
            <b-dropdown v-if="StoreFrontendSettings && StoreFrontendSettings.data && StoreFrontendSettings.data.are_shop_pages_active === '1'" variant="white" style="padding: 0px;" no-caret>
              <template slot="button-content">
                <span :style="f_calculateSelectedCurrencyStyle()"> {{ d_selectedCurrencyType.label }} </span>
              </template>
              <template v-for="(cur_type, cur_type_index) in StoreCurrencyTypeList.data">
                <b-dropdown-item @click="f_selectCurrencyType(cur_type)">
                  <i class="fa fa-money"></i> {{ cur_type.label }}
                </b-dropdown-item>
              </template>
            </b-dropdown>
            <b-button v-if="StoreFrontendSettings && StoreFrontendSettings.data && StoreFrontendSettings.data.are_shop_pages_active === '1'" size="lg" style="padding: 0px; font-size: 15px;" variant="white">
              <div class="shopping-cart-button">
                <template v-if="d_user && d_user.username">
                  <a :href="f_createThisRouteHrefLink('user_shoppingcart')">
                    <span :style="f_calculateShopcartButtonStyle()"> {{ StoreLangTranslation.data['my_shopcart'][StoreLang] }} </span>
                  </a>
                  <span class="shopping-cart-count"> {{ f_calculateShopcartBundleCount() }} </span>
                </template>
                <template v-else>
                  <span @click="f_login()"> {{ StoreLangTranslation.data['my_shopcart'][StoreLang] }} </span>
                </template>
              </div>
            </b-button>
            <b-button v-if="StoreFrontendSettings && StoreFrontendSettings.data && StoreFrontendSettings.data.are_shop_pages_active === '1' && StoreShopCartOrderList.data.length > 0" size="lg" style="padding: 0px; font-size: 15px;" variant="white">
              <div class="shopping-cart-button">
                <a :href="f_createThisRouteHrefLink('user_shopcart_bundle_order_list')">
                  <span :style="f_calculateShopcartButtonStyle()"> {{ StoreLangTranslation.data['order'][StoreLang] }} </span>
                </a>
                <span class="shopping-cart-count"> {{ f_calculateBundleOrderCount() }} </span>
              </div>
            </b-button>
            <b-dropdown variant="white" style="padding: 0px;" no-caret>
              <template slot="button-content">
                <span :style="f_calculateSelectedLangStyle()"> {{ d_selectedLang }} </span>
              </template>
              <template v-for="(lang, lang_index) in StoreLangList.data">
                <b-dropdown-item @click="f_selectLang(lang)">
                  <i class="fa fa-language"></i> {{ lang.label }}
                </b-dropdown-item>
              </template>
            </b-dropdown>
            <b-button v-if="d_user && d_user.username" title="Çıkış Yap" @click="f_logout()" size="lg" style="padding: 0px;" variant="white">
              <i class="fa fa-user-circle" :style="f_calculateUsernameShowStyle()"></i>
              <span v-if="!StoreDevice.isMobile" :style="f_calculateUsernameShowStyle()"> {{ f_showUserName() }} </span>
            </b-button>
            <b-button v-if="!d_user && StoreFrontendSettings && StoreFrontendSettings.data.login_function === '1'" title="Login" @click="f_login()" size="lg" style="padding: 0px; font-size: 15px;" variant="white">
              {{ StoreLangTranslation.data['login'][StoreLang] }}
              <!-- <img src="@/icon/633951.png" width="40px;" /> -->
            </b-button>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; height: 75px; font-size: 20px; font-weight: 700;">
          <b-col cols="2"> </b-col>
          <b-col cols="2" style="margin-top: 15px;">
            <strong style="cursor: pointer;" @click="f_goToMainPage()">
              <img v-if="StoreFrontendSettings.data.navbar_middle_logo" :src="StoreFrontendSettings.data.navbar_middle_logo" style="height: 50px;" />
            </strong>
            <!-- <span v-if="!StoreDevice.isMobile && StoreFrontendSettings.data.application_name && StoreFrontendSettings.data.application_name[StoreLang]" title="Anasayfa" :style="f_styleNavbarApplicationNameTopMenu()" @click="f_goToMainPage()"> {{ StoreFrontendSettings.data.application_name[StoreLang] }}</span> -->
          </b-col>
          <b-col cols="6" style="padding: 0px; margin-top: 15px;">
            <template v-for="(menu_item, menu_item_index) in StoreMenuData.data.list">
              <!-- <template v-if="f_checkMenuPermission(menu_item) && d_topShowMenuIndexList.indexOf(menu_item_index) !== -1"> -->
              <template v-if="f_checkMenuPermission(menu_item)">
                <!-- <template v-if="(menu_item.show_in_menu && menu_item.show_in_menu.value === '1') || (menu_item.route_path && menu_item.route_path[StoreLang] === $route.path)"> -->
                <template v-if="(menu_item.show_in_menu && menu_item.show_in_menu.value === '1')">
                    <template v-if="menu_item.route_path">
                      <b-dropdown variant="white" style="padding: 0px;" :ref="'menu_item__' + menu_item_index">
                          <template slot="button-content">
                            <template v-if="menu_item.caption[StoreLang].indexOf('</') !== -1">
                              <!--
                                <template v-if="menu_item.meta && menu_item.meta.fa_icon">
                                  <i :class="menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('parent')"></i>
                                </template>
                              -->
                              <div :style="f_calculateMenuCaptionStyle('parent')" v-html="menu_item.caption[StoreLang]"></div>
                            </template>
                            <template v-else>
                              <!--
                                <template v-if="menu_item.meta && menu_item.meta.fa_icon">
                                  <i :class="menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('parent')"></i>
                                </template>
                              -->
                              <strong @mouseover="f_menuMouseOver(menu_item_index)" :style="f_calculateMenuCaptionStyle('parent', menu_item_index)" @click="f_goToThisPage(menu_item)">
                                {{ menu_item.caption[StoreLang] }}
                              </strong>
                            </template>
                          </template>
                          <template v-if="menu_item.list && menu_item.list.length > 0">
                            <template v-for="(sub_menu_item, sub_menu_item_index) in menu_item.list">
                              <template v-if="f_checkMenuPermission(sub_menu_item)">
                                <template v-if="(sub_menu_item.show_in_menu && sub_menu_item.show_in_menu.value === '1')">
                                  <template v-if="sub_menu_item.route_path">
                                    <template v-if="f_checkLoginMenuItem(sub_menu_item)">
                                      <b-dropdown-item :href="f_createHrefLink(sub_menu_item)">
                                          <!-- <i class="fa fa-angle-double-right" style="margin-left: 5px;"></i> -->
                                          <template v-if="sub_menu_item.caption[StoreLang].indexOf('</') !== -1">
                                            <template v-if="sub_menu_item.meta && sub_menu_item.meta.fa_icon">
                                              <i :class="sub_menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('child')"></i>
                                            </template>
                                            <div :style="f_calculateMenuCaptionStyle('child')" v-html="sub_menu_item.caption[StoreLang]"></div>
                                          </template>
                                          <template v-else>
                                            <template v-if="sub_menu_item.meta && sub_menu_item.meta.fa_icon">
                                              <i :class="sub_menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('child')"></i>
                                            </template>
                                            <strong :style="f_calculateMenuCaptionStyle('child')"> {{ sub_menu_item.caption[StoreLang] }} </strong>
                                          </template>
                                      </b-dropdown-item>
                                    </template>
                                    <template v-else>
                                      <b-dropdown-item @click="f_login()">
                                          <!-- <i class="fa fa-angle-double-right" style="margin-left: 5px;"></i> -->
                                          <template v-if="sub_menu_item.caption[StoreLang].indexOf('</') !== -1">
                                            <template v-if="sub_menu_item.meta && sub_menu_item.meta.fa_icon">
                                              <i :class="sub_menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('child')"></i>
                                            </template>
                                            <div :style="f_calculateMenuCaptionStyle('child')" v-html="sub_menu_item.caption[StoreLang]"></div>
                                          </template>
                                          <template v-else>
                                            <template v-if="sub_menu_item.meta && sub_menu_item.meta.fa_icon">
                                              <i :class="sub_menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('child')"></i>
                                            </template>
                                            <strong :style="f_calculateMenuCaptionStyle('child')"> {{ sub_menu_item.caption[StoreLang] }} </strong>
                                          </template>
                                      </b-dropdown-item>
                                    </template>
                                  </template>
                                  <template v-else-if="sub_menu_item.link">                                
                                    <b-dropdown-item @click="f_goToThisUrl(sub_menu_item.link[StoreLang])">
                                        <template v-if="sub_menu_item.meta && sub_menu_item.meta.fa_icon">
                                          <i :class="sub_menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('child')"></i>
                                        </template>
                                        <i class="fa fa-angle-double-right"></i> {{ sub_menu_item.caption[StoreLang] }} }}
                                    </b-dropdown-item>
                                  </template>
                                  <template v-else>                                
                                    <b-dropdown-item>
                                        <span v-if="sub_menu_item.caption">
                                          <template v-if="sub_menu_item.meta && sub_menu_item.meta.fa_icon">
                                            <i :class="sub_menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('child')"></i>
                                          </template>
                                          <i class="fa fa-angle-right"></i> {{ sub_menu_item.caption[StoreLang] }}
                                        </span>
                                    </b-dropdown-item>
                                  </template>
                                </template>
                              </template>
                            </template>
                          </template>
                      </b-dropdown>
                    </template>
                    <template v-else-if="menu_item.link">
                      <span @click="f_goToThisUrl(menu_item.link[StoreLang])" :style="f_calculateTopMenuItemStyle('parent', menu_item)">
                        <template v-if="menu_item.meta && menu_item.meta.fa_icon">
                          <i :class="menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('parent')"></i>
                        </template>
                        {{ menu_item.caption[StoreLang] }} }}
                      </span>
                    </template>
                    <template v-else>
                      <span v-if="menu_item.caption" :style="f_calculateTopMenuItemStyle('parent', menu_item)">
                        <template v-if="menu_item.meta && menu_item.meta.fa_icon">
                          <i :class="menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('parent')"></i>
                        </template>
                        {{ menu_item.caption[StoreLang] }}
                      </span>
                    </template>
                </template>
              </template>
            </template>
            <template v-if="false">
              <b-dropdown variant="white" style="padding: 0px;">
                <template slot="button-content">
                  <i class="fa fa-th-list"></i>
                </template>
                <div style="overflow-x: hidden; overflow-y: auto; height: 500px; font-size: 14px;">
                  <template v-for="(menu_item, menu_item_index) in StoreMenuData.data.list">
                      <template v-if="f_checkMenuPermission(menu_item)">          
                        <template v-if="(menu_item.show_in_menu && menu_item.show_in_menu.value === '1')">
                            <template v-if="menu_item.route_path">
                                <template v-if="f_checkLoginMenuItem(menu_item)">
                                  <b-dropdown-item :href="f_createHrefLink(menu_item)">
                                    <template v-if="menu_item.caption[StoreLang].indexOf('</') !== -1">
                                      <template v-if="menu_item.meta && menu_item.meta.fa_icon">
                                        <i :class="menu_item.meta.fa_icon"  :style="f_calculateMenuCaptionStyle('parent')"></i>
                                      </template>
                                      <div :style="f_calculateMenuCaptionStyle('parent')" v-html="menu_item.caption[StoreLang]"></div>
                                    </template>
                                    <template v-else>
                                      <template v-if="menu_item.meta && menu_item.meta.fa_icon">
                                        <i :class="menu_item.meta.fa_icon"  :style="f_calculateMenuCaptionStyle('parent')"></i>
                                      </template>
                                      <strong :style="f_calculateMenuCaptionStyle('parent')"> {{ menu_item.caption[StoreLang] }} </strong>
                                    </template>
                                  </b-dropdown-item>
                                </template>
                                <template v-else>
                                  <b-dropdown-item @click="f_login()">
                                    <template v-if="menu_item.caption[StoreLang].indexOf('</') !== -1">
                                      <template v-if="menu_item.meta && menu_item.meta.fa_icon">
                                        <i :class="menu_item.meta.fa_icon"  :style="f_calculateMenuCaptionStyle('parent')"></i>
                                      </template>
                                      <div :style="f_calculateMenuCaptionStyle('parent')" v-html="menu_item.caption[StoreLang]"></div>
                                    </template>
                                    <template v-else>
                                      <template v-if="menu_item.meta && menu_item.meta.fa_icon">
                                        <i :class="menu_item.meta.fa_icon"  :style="f_calculateMenuCaptionStyle('parent')"></i>
                                      </template>
                                      <strong :style="f_calculateMenuCaptionStyle('parent')"> {{ menu_item.caption[StoreLang] }} </strong>
                                    </template>
                                  </b-dropdown-item>
                                </template>
                                <template v-if="menu_item.list && menu_item.list.length > 0">
                                  <template v-for="(sub_menu_item, sub_menu_item_index) in menu_item.list">
                                    <template v-if="f_checkMenuPermission(sub_menu_item)">
                                      <template v-if="(sub_menu_item.show_in_menu && sub_menu_item.show_in_menu.value === '1')">
                                        <template v-if="sub_menu_item.route_path">
                                          <template v-if="f_checkLoginMenuItem(sub_menu_item)">
                                            <b-dropdown-item :href="f_createHrefLink(sub_menu_item)">
                                                <!-- <i class="fa fa-angle-double-right" style="margin-left: 5px;"></i> -->
                                                <template v-if="sub_menu_item.caption[StoreLang].indexOf('</') !== -1">
                                                  <template v-if="sub_menu_item.meta && sub_menu_item.meta.fa_icon">
                                                    <i :class="sub_menu_item.meta.fa_icon"  :style="f_calculateMenuCaptionStyle('child')"></i>
                                                  </template>
                                                  <div :style="f_calculateMenuCaptionStyle('child')" v-html="sub_menu_item.caption[StoreLang]"></div>
                                                </template>
                                                <template v-else>
                                                  <template v-if="sub_menu_item.meta && sub_menu_item.meta.fa_icon">
                                                    <i :class="sub_menu_item.meta.fa_icon"  :style="f_calculateMenuCaptionStyle('child')"></i>
                                                  </template>
                                                  <strong :style="f_calculateMenuCaptionStyle('child')"> {{ sub_menu_item.caption[StoreLang] }} </strong>
                                                </template>
                                            </b-dropdown-item>
                                          </template>
                                          <template v-else>
                                            <b-dropdown-item @click="f_login()">
                                                <!-- <i class="fa fa-angle-double-right" style="margin-left: 5px;"></i> -->
                                                <template v-if="sub_menu_item.caption[StoreLang].indexOf('</') !== -1">
                                                  <template v-if="sub_menu_item.meta && sub_menu_item.meta.fa_icon">
                                                    <i :class="sub_menu_item.meta.fa_icon"  :style="f_calculateMenuCaptionStyle('child')"></i>
                                                  </template>
                                                  <div :style="f_calculateMenuCaptionStyle('child')" v-html="sub_menu_item.caption[StoreLang]"></div>
                                                </template>
                                                <template v-else>
                                                  <template v-if="sub_menu_item.meta && sub_menu_item.meta.fa_icon">
                                                    <i :class="sub_menu_item.meta.fa_icon"  :style="f_calculateMenuCaptionStyle('child')"></i>
                                                  </template>
                                                  <strong :style="f_calculateMenuCaptionStyle('child')"> {{ sub_menu_item.caption[StoreLang] }} </strong>
                                                </template>
                                            </b-dropdown-item>
                                          </template>
                                        </template>
                                        <template v-else-if="sub_menu_item.link">
                                          <b-dropdown-item @click="f_goToThisUrl(sub_menu_item.link[StoreLang])">
                                              <div :style="f_calculateMenuCaptionStyle('child')">
                                                <template v-if="sub_menu_item.meta && sub_menu_item.meta.fa_icon">
                                                  <i :class="sub_menu_item.meta.fa_icon"  :style="f_calculateMenuCaptionStyle('child')"></i>
                                                </template>
                                                <i class="fa fa-angle-double-right"></i> {{ sub_menu_item.caption[StoreLang] }} }}
                                              </div>
                                          </b-dropdown-item>
                                        </template>
                                        <template v-else>
                                          <b-dropdown-item>
                                              <div :style="f_calculateMenuCaptionStyle('child')">
                                                <span v-if="sub_menu_item.caption">
                                                  <template v-if="sub_menu_item.meta && sub_menu_item.meta.fa_icon">
                                                    <i :class="sub_menu_item.meta.fa_icon"  :style="f_calculateMenuCaptionStyle('child')"></i>
                                                  </template>
                                                  <i class="fa fa-angle-right"></i> {{ sub_menu_item.caption[StoreLang] }}
                                                </span>
                                              </div>
                                          </b-dropdown-item>
                                        </template>
                                      </template>
                                    </template>
                                  </template>
                                </template>
                            </template>
                            <template v-else-if="menu_item.link">
                              <span @click="f_goToThisUrl(menu_item.link[StoreLang])" :style="f_calculateTopMenuItemStyle('parent', menu_item)">
                                <template v-if="menu_item.meta && menu_item.meta.fa_icon">
                                  <i :class="menu_item.meta.fa_icon"></i>
                                </template>
                                {{ menu_item.caption[StoreLang] }} }}
                              </span>
                            </template>
                            <template v-else>
                              <span v-if="menu_item.caption" :style="f_calculateTopMenuItemStyle('parent', menu_item)">
                                <template v-if="menu_item.meta && menu_item.meta.fa_icon">
                                  <i :class="menu_item.meta.fa_icon"></i>
                                </template>
                                {{ menu_item.caption[StoreLang] }}
                              </span>
                            </template>
                        </template>
                      </template>
                  </template>
                </div>
              </b-dropdown>
            </template>
            <b-button variant="outline-primary" style="border-radius: 20px; height: 50px; font-size: 20px;" @click="f_goToContactUsPage()">
              {{ StoreLangTranslation.data['contact_us'][StoreLang] }}
            </b-button>
          </b-col>
          <b-col cols="2">
            
          </b-col>
        </b-row>
      </div>
    <!-- left menu mode -->
    <!-- <div class="app-body" :style="d_showAplicationHeader ? 'margin-bottom: 0px;' : 'margin-top: 0px;'"> -->
    <div :style="d_showAplicationHeader ? 'margin-bottom: 0px; overflow-x: hidden;' : 'margin-top: 0px; overflow-x: hidden;'">
      <div v-if="((['0', '2'].indexOf(StoreFrontendSettings.data.menu_type) !== -1) || StoreDevice.isMobile) && d_showAplicationHeader" class="navbar_class">
        <b-row style="margin: 0px;">
          <b-col cols="5">
            <i class="fa fa-navicon" @click="d_showAplicationSidebar ? d_showAplicationSidebar = false : d_showAplicationSidebar = true" :style="'color: ' + StoreFrontendSettings.data.menu_parent_item_text_color + '; font-weight: 750; font-size: 30px; padding: 5px;'"></i>
            <span v-if="!StoreDevice.isMobile && StoreFrontendSettings.data.application_name && StoreFrontendSettings.data.application_name[StoreLang]" title="Anasayfa" :style="f_styleNavbarApplicationName()" @click="f_goToMainPage()"> {{ StoreFrontendSettings.data.application_name[StoreLang] }}</span>
          </b-col>
          <b-col v-if="!StoreDevice.isMobile" cols="2" style="text-align: center; margin-top: 10px;">
            <strong style="cursor: pointer; font-size: 16px;" @click="f_goToMainPage()">
              <img v-if="StoreFrontendSettings.data.navbar_middle_logo" :src="StoreFrontendSettings.data.navbar_middle_logo" style="height: 2em;" />
            </strong>
          </b-col>
          <b-col :cols="StoreDevice.isMobile ? 7 : 5" style="text-align: right;">
            <b-dropdown v-if="StoreFrontendSettings && StoreFrontendSettings.data && StoreFrontendSettings.data.are_shop_pages_active === '1'" variant="white" style="padding: 0px;" no-caret>
              <template slot="button-content">
                <span :style="f_calculateSelectedCurrencyStyle()"> {{ d_selectedCurrencyType.label }} </span>
              </template>
              <template v-for="(cur_type, cur_type_index) in StoreCurrencyTypeList.data">
                <b-dropdown-item @click="f_selectCurrencyType(cur_type)">
                  <i class="fa fa-money"></i> {{ cur_type.label }}
                </b-dropdown-item>
              </template>
            </b-dropdown>
            <b-dropdown variant="white" style="padding: 0px; margin-right: 10px;" no-caret>
              <template slot="button-content">
                <span :style="f_calculateSelectedLangStyle()"> {{ d_selectedLang }} </span>
              </template>
              <template v-for="(lang, lang_index) in StoreLangList.data">
                <b-dropdown-item @click="f_selectLang(lang)">
                  <i class="fa fa-language"></i> {{ lang.label }}
                </b-dropdown-item>
              </template>
            </b-dropdown>
            <b-button v-if="d_user && d_user.username" title="Çıkış Yap" @click="f_logout()" size="lg" style="padding: 0px;" variant="white">
              <i class="fa fa-user"></i>
              <span v-if="!StoreDevice.isMobile" style="font-family: roboto; font-size: 14px; padding: 3px; text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);"> {{ f_showUserName() }} </span>
            </b-button>
            <b-button v-if="!d_user && StoreFrontendSettings && StoreFrontendSettings.data.login_function === '1'" title="Login" @click="f_login()" size="lg" style="padding: 0px;" variant="white">
              {{ StoreLangTranslation.data['login'][StoreLang] }}
              <!-- <img src="@/icon/633951.png" width="40px;" /> -->
              <i class="fa fa-user-circle-o"></i>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <!-- left menu -->
      <template v-if="StoreDevice.isMobile || (['0', '2'].indexOf(StoreFrontendSettings.data.menu_type) !== -1)">
        <div v-if="d_showAplicationSidebar" :style="f_sideBarStyle()" @click="f_menuAreaClick()">
          <b-row v-if="StoreDevice.isMobile">
            <b-col cols="12" :style="f_calculateMenuItemStyle('parent', '')">
              <strong v-if="StoreFrontendSettings.data.menu_top_caption" style="margin: 5px;" @click="f_goToMainPage()">
                <i class="fa fa-angle-right"></i>
                <img :src="StoreFrontendSettings.data.navbar_middle_logo" style="height: 50px;" />
                <!-- {{ StoreFrontendSettings.data.menu_top_caption[StoreLang] }} -->
              </strong>
            </b-col>
          </b-row>
          <template v-for="(menu_item, menu_item_index) in StoreMenuData.data.list">
            <template v-if="f_checkMenuPermission(menu_item)">          
              <template v-if="(menu_item.show_in_menu && menu_item.show_in_menu.value === '1')">
                <b-row v-if="menu_item.caption" :style="f_calculateMenuItemStyle('parent', menu_item)">
                  <template v-if="menu_item.route_path">
                    <template v-if="f_checkLoginMenuItem(menu_item)">
                      <b-col cols="12">
                        <a :href="f_createHrefLink(menu_item)">
                          <!-- <i class="fa fa-angle-right"></i> -->
                          <!-- <i class="fa fa-train" style="margin-left: 0px;"></i> -->
                          <!-- <img src="@/icon/2751485.png" style="width: 30px;" /> -->
                          <template v-if="menu_item.caption[StoreLang].indexOf('</') !== -1">
                            <template v-if="menu_item.meta && menu_item.meta.fa_icon">
                              <i :class="menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('parent')"></i>
                            </template>
                            <div v-html="menu_item.caption[StoreLang]" :style="f_calculateMenuCaptionStyle('parent')"></div>
                          </template>
                          <template v-else>
                            <template v-if="menu_item.meta && menu_item.meta.fa_icon">
                              <i :class="menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('parent')"></i>
                            </template>
                            <span :style="f_calculateMenuCaptionStyle('parent')"> {{ menu_item.caption[StoreLang] }} </span>
                          </template>
                        </a>
                        <!-- <span style="float: right;"> <img v-if="menu_item.login === 1" src="@/icon/9370.png" style="width: 10px;" /> </span> -->
                      </b-col>
                    </template>
                    <template v-else>
                        <div @click="f_login()">
                          <!-- <i class="fa fa-angle-right"></i> -->
                          <!-- <i class="fa fa-train" style="margin-left: 0px;"></i> -->
                          <!-- <img src="@/icon/2751485.png" style="width: 30px;" /> -->
                          <template v-if="menu_item.caption[StoreLang].indexOf('</') !== -1">
                            <template v-if="menu_item.meta && menu_item.meta.fa_icon">
                              <i :class="menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('parent')"></i>
                            </template>
                            <div v-html="menu_item.caption[StoreLang]" :style="f_calculateMenuCaptionStyle('parent')"></div>
                          </template>
                          <template v-else>
                            <template v-if="menu_item.meta && menu_item.meta.fa_icon">
                              <i :class="menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('parent')"></i>
                            </template>
                            <span :style="f_calculateMenuCaptionStyle('parent')"> {{ menu_item.caption[StoreLang] }} </span>
                          </template>
                        </div>
                        <!-- <span style="float: right;"> <img v-if="menu_item.login === 1" src="@/icon/9370.png" style="width: 10px;" /> </span> -->                      
                    </template>
                  </template>
                  <template v-else-if="menu_item.link">
                    <b-col cols="12" @click="f_goToThisUrl(menu_item.link[StoreLang])">
                      <template v-if="menu_item.meta && menu_item.meta.fa_icon">
                        <i :class="menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('parent')"></i>
                      </template>
                      <i class="fa fa-angle-right"></i>
                      <span :style="f_calculateMenuCaptionStyle('parent')">                        
                        {{ menu_item.caption[StoreLang] }}
                      </span>
                        <img src="@/icon/2230573.png" style="width: 30px;" />
                    </b-col>
                  </template>
                  <template v-else>
                    <b-col cols="12">
                      <span v-if="menu_item.caption">
                        <img src="@/icon/2751485.png" style="width: 30px;" />
                        <!-- <i class="fa fa-angle-right"></i> -->
                        <template v-if="menu_item.meta && menu_item.meta.fa_icon">
                          <i :class="menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('parent')"></i>
                        </template>
                        <span :style="f_calculateMenuCaptionStyle('parent')">
                          {{ menu_item.caption[StoreLang] }}
                        </span>
                      </span>
                    </b-col>
                  </template>
                </b-row>
              </template>
              <template v-if="menu_item.list && menu_item.list.length > 0">
                <template v-for="(sub_menu_item, sub_menu_item_index) in menu_item.list">
                  <template v-if="f_checkMenuPermission(sub_menu_item)">
                    <template v-if="(sub_menu_item.show_in_menu && sub_menu_item.show_in_menu.value === '1')">
                        <b-row :style="f_calculateMenuItemStyle('child', sub_menu_item)">
                          <template v-if="sub_menu_item.route_path">
                            <template v-if="f_checkLoginMenuItem(sub_menu_item)">
                              <b-col cols="12">
                                <a :href="f_createHrefLink(sub_menu_item)" :style="StoreFrontendSettings.data.menu_child_item_text_color ? 'color: ' + StoreFrontendSettings.data.menu_child_item_text_color : ''">
                                  <!-- <i class="fa fa-angle-double-right" style="margin-left: 5px;"></i> -->
                                  <!-- <i class="fa fa-ticket" style="margin-left: 15px;"></i> -->
                                  <!-- <img src="@/icon/2751485.png" style="width: 25px; margin-left: 10px;" /> -->
                                  <template v-if="sub_menu_item.caption[StoreLang].indexOf('</') !== -1">
                                    <template v-if="sub_menu_item.meta && sub_menu_item.meta.fa_icon">
                                      <i :class="sub_menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('child')"></i>
                                    </template>
                                    <div v-html="sub_menu_item.caption[StoreLang]" :style="f_calculateMenuCaptionStyle('child')"></div>
                                  </template>
                                  <template v-else>
                                    <template v-if="sub_menu_item.meta && sub_menu_item.meta.fa_icon">
                                      <i :class="sub_menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('child')"></i>
                                    </template>
                                    <span :style="f_calculateMenuCaptionStyle('child')"> {{ sub_menu_item.caption[StoreLang] }} </span>
                                  </template>
                                </a>
                              </b-col>
                            </template>
                            <template v-else>
                              <b-col cols="12">
                                <div :style="StoreFrontendSettings.data.menu_child_item_text_color ? 'color: ' + StoreFrontendSettings.data.menu_child_item_text_color : ''" @click="f_login()">
                                  <!-- <i class="fa fa-angle-double-right" style="margin-left: 5px;"></i> -->
                                  <!-- <i class="fa fa-ticket" style="margin-left: 15px;"></i> -->
                                  <!-- <img src="@/icon/2751485.png" style="width: 25px; margin-left: 10px;" /> -->
                                  <template v-if="sub_menu_item.caption[StoreLang].indexOf('</') !== -1">
                                    <template v-if="sub_menu_item.meta && sub_menu_item.meta.fa_icon">
                                      <i :class="sub_menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('child')"></i>
                                    </template>
                                    <div v-html="sub_menu_item.caption[StoreLang]"></div>
                                  </template>
                                  <template v-else>
                                    <template v-if="sub_menu_item.meta && sub_menu_item.meta.fa_icon">
                                      <i :class="sub_menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('child')"></i>
                                    </template>
                                    <span :style="f_calculateMenuCaptionStyle('child')"> {{ sub_menu_item.caption[StoreLang] }} </span>
                                  </template>
                                </div>
                              </b-col>
                            </template>
                          </template>
                          <template v-else-if="sub_menu_item.link">
                            <b-col cols="12" @click="f_goToThisUrl(sub_menu_item.link[StoreLang])">
                              <template v-if="sub_menu_item.meta && sub_menu_item.meta.fa_icon">
                                <i :class="sub_menu_item.meta.fa_icon"></i>
                              </template>
                              <i class="fa fa-angle-double-right"></i> {{ sub_menu_item.caption[StoreLang] }} }} <img src="@/icon/2230573.png" style="width: 30px;" />
                            </b-col>
                          </template>
                          <template v-else>
                            <b-col cols="12">
                              <span v-if="sub_menu_item.caption">
                                <!-- <img src="@/icon/2751485.png" style="width: 30px;" /> -->
                                <template v-if="sub_menu_item.meta && sub_menu_item.meta.fa_icon">
                                  <i :class="sub_menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('child')"></i>
                                </template>
                                <i class="fa fa-angle-right"></i>
                                <span :style="f_calculateMenuCaptionStyle('child')">
                                  {{ sub_menu_item.caption[StoreLang] }}
                                </span>
                              </span>
                            </b-col>
                          </template>
                        </b-row>
                      </template>
                  </template>
                </template>
              </template>
              <div :style="'border-bottom: solid 1px ' + StoreFrontendSettings.data.menu_border_bottom_color + ';'"></div>
            </template>
          </template>
        </div>
      </template>
      <main :style="f_calculateMainAreaStyle()" @click="f_clickMainArea()">
        <div id="router-div">
          <router-view></router-view>
        </div>
          <b-row v-if="d_showAplicationFooter" :style="f_calculateFooterStyle()">
          <b-col cols="2">
          </b-col>
          <b-col cols="8" style="padding: 0px;">
            <b-row>
              <template v-for="(menu_item, menu_item_index) in StoreMenuData.data.list">
                <template v-if="f_checkMenuPermission(menu_item)">          
                  <template v-if="menu_item.show_in_menu && menu_item.show_in_menu.value === '1'">
                      <template v-if="menu_item.route_path">
                        <b-col sm="12" md="12" lg="4" style="padding: 20px;">
                          <template v-if="f_checkLoginMenuItem(menu_item)">
                            <div @click="f_goToThisPage(menu_item)" style="cursor: pointer; font-size: 16px; font-weight: bold; padding: 10px;">
                              <template v-if="menu_item.caption[StoreLang].indexOf('</') !== -1">
                                <template v-if="menu_item.meta && menu_item.meta.fa_icon">
                                  <i :class="menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('parent')"></i>
                                </template>
                                <div v-html="menu_item.caption[StoreLang]" :style="f_calculateMenuCaptionStyle('parent')"></div>
                              </template>
                              <template v-else>
                                <template v-if="menu_item.meta && menu_item.meta.fa_icon">
                                  <i :class="menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('parent')"></i>
                                </template>
                                <span :style="f_calculateMenuCaptionStyle('parent')"> {{ menu_item.caption[StoreLang] }} </span>
                              </template>
                            </div>
                          </template>
                          <template v-else>
                            <div @click="f_login()" style="cursor: pointer; font-size: 16px; font-weight: bold; padding: 10px;">
                              <template v-if="menu_item.caption[StoreLang].indexOf('</') !== -1">
                                <template v-if="menu_item.meta && menu_item.meta.fa_icon">
                                  <i :class="menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('parent')"></i>
                                </template>
                                <div v-html="menu_item.caption[StoreLang]" :style="f_calculateMenuCaptionStyle('parent')"></div>
                              </template>
                              <template v-else>
                                <template v-if="menu_item.meta && menu_item.meta.fa_icon">
                                  <i :class="menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('parent')"></i>
                                </template>
                                <span :style="f_calculateMenuCaptionStyle('parent')"> {{ menu_item.caption[StoreLang] }} </span>
                              </template>
                            </div>                            
                          </template>
                          <template v-if="menu_item.list && menu_item.list.length > 0">
                            <template v-for="(sub_menu_item, sub_menu_item_index) in menu_item.list">
                              <template v-if="f_checkMenuPermission(sub_menu_item)">
                                <template v-if="(sub_menu_item.show_in_menu && sub_menu_item.show_in_menu.value === '1')">
                                    <template v-if="sub_menu_item.route_path">
                                      <template v-if="f_checkLoginMenuItem(sub_menu_item)">
                                        <div @click="f_goToThisPage(sub_menu_item)" style="cursor: pointer; padding: 10px; font-size: 14px;">
                                          <template v-if="sub_menu_item.caption[StoreLang].indexOf('</') !== -1">
                                            <template v-if="sub_menu_item.meta && sub_menu_item.meta.fa_icon">
                                              <i :class="sub_menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('child')"></i>
                                            </template>
                                            <div v-html="sub_menu_item.caption[StoreLang]" :style="f_calculateMenuCaptionStyle('child')"></div>
                                          </template>
                                          <template v-else>
                                            <template v-if="sub_menu_item.meta && sub_menu_item.meta.fa_icon">
                                              <i :class="sub_menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('child')"></i>
                                            </template>
                                            <span :style="f_calculateMenuCaptionStyle('child')"> {{ sub_menu_item.caption[StoreLang] }} </span>
                                          </template>
                                        </div>
                                      </template>
                                      <template v-else>
                                        <div @click="f_login()" style="cursor: pointer; padding: 10px; font-size: 14px;">
                                          <template v-if="sub_menu_item.caption[StoreLang].indexOf('</') !== -1">
                                            <template v-if="sub_menu_item.meta && sub_menu_item.meta.fa_icon">
                                              <i :class="sub_menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('child')"></i>
                                            </template>
                                            <div v-html="sub_menu_item.caption[StoreLang]" :style="f_calculateMenuCaptionStyle('child')"></div>
                                          </template>
                                          <template v-else>
                                            <template v-if="sub_menu_item.meta && sub_menu_item.meta.fa_icon">
                                              <i :class="sub_menu_item.meta.fa_icon" :style="f_calculateMenuCaptionStyle('child')"></i>
                                            </template>
                                            <span :style="f_calculateMenuCaptionStyle('child')"> {{ sub_menu_item.caption[StoreLang] }} </span>
                                          </template>
                                        </div>
                                      </template>
                                    </template>
                                    <template v-else-if="sub_menu_item.link">
                                      <div @click="f_goToThisUrl(sub_menu_item.link[StoreLang])" style="cursor: pointer; padding: 10px; font-size: 14px;">
                                         <template v-if="sub_menu_item.meta && sub_menu_item.meta.fa_icon">
                                           <i :class="sub_menu_item.meta.fa_icon"></i>
                                         </template>
                                         <span :style="f_calculateMenuCaptionStyle('child')">
                                          {{ sub_menu_item.caption[StoreLang] }}
                                         </span>
                                         <img src="@/icon/2230573.png" style="width: 30px;" />
                                      </div>
                                    </template>
                                    <template v-else>
                                      <div style="cursor: pointer; padding: 10px; font-size: 14px;">
                                        <span v-if="sub_menu_item.caption" :style="f_calculateMenuCaptionStyle('child')">
                                          <template v-if="sub_menu_item.meta && sub_menu_item.meta.fa_icon">
                                            <i :class="sub_menu_item.meta.fa_icon"></i>
                                          </template>
                                          {{ sub_menu_item.caption[StoreLang] }}
                                        </span>
                                      </div>
                                    </template>
                                </template>
                              </template>
                            </template>
                          </template>
                        </b-col>
                      </template>
                      <template v-else-if="menu_item.link">
                        <span @click="f_goToThisUrl(menu_item.link[StoreLang])" :style="f_calculateMenuCaptionStyle('parent')">
                          <template v-if="menu_item.meta && menu_item.meta.fa_icon">
                            <i :class="menu_item.meta.fa_icon"></i>
                          </template>
                          {{ menu_item.caption[StoreLang] }} <img src="@/icon/2230573.png" style="width: 30px;" />
                        </span>
                      </template>
                      <template v-else>
                        <span v-if="menu_item.caption" :style="f_calculateMenuCaptionStyle('parent')">
                          <template v-if="menu_item.meta && menu_item.meta.fa_icon">
                            <i :class="menu_item.meta.fa_icon"></i>
                          </template>
                          {{ menu_item.caption[StoreLang] }}
                        </span>
                      </template>
                  </template>
                </template>
              </template>
            </b-row>
          </b-col>
          <b-col cols="2"></b-col>
        </b-row>
        <b-row v-if="d_showAplicationFooter" :style="f_calculateFooterBelowStyle()">
          <b-col sm="12" lg="2">
          </b-col>
          <b-col sm="12" lg="4">
            <p v-if="StoreFrontendSettings.data.navbar_middle_logo">
              <span >
                <img :src="StoreFrontendSettings.data.navbar_middle_logo" style="height: 50px;" />
              </span>
            </p>
            <p>
              <small>© Copyright {{ StoreFrontendSettings.data.footer_date ? StoreFrontendSettings.data.footer_date : '' }}. All Rights Reserved.</small>
            </p>
          </b-col>
          <b-col sm="12" lg="2">
              <a v-if="StoreFrontendSettings.data.facebook_link" :href="StoreFrontendSettings.data.facebook_link" target="_target" style="margin-right: 10px;"><i class="fa fa-facebook"> </i></a>
              <a v-if="StoreFrontendSettings.data.twitter_link" :href="StoreFrontendSettings.data.twitter_link" target="_target" style="margin-right: 10px;"><i class="fa fa-twitter"></i></a>
              <a v-if="StoreFrontendSettings.data.instagram_link" :href="StoreFrontendSettings.data.instagram_link" target="_target" style="margin-right: 10px;"><i class="fa fa-instagram"></i></a>
              <a v-if="StoreFrontendSettings.data.linkedin_link" :href="StoreFrontendSettings.data.linkedin_link" target="_target" style="margin-right: 10px;"><i class="fa fa-linkedin"></i></a>
          </b-col>
          <b-col sm="12" lg="2"></b-col>
          <b-col sm="12" lg="2"></b-col>
        </b-row>
      </main>
    </div>
    <b-modal id="store-loading" v-model="d_showLoadingModal" no-close-on-esc no-close-on-backdrop centered hide-footer hide-header-close body-bg-variant="dark" style="border-radius: 30px!important; z-index: 9999; box-shadow: -1px -1px 1px;">
      <b-row v-if="StoreLoading.data.label" style="vertical-align: middle; text-align: center;">
        <b-col sm="12" lg="1">
          <img src="../../public/img/gif/right-left.gif" class="img-rounded img-responsive" style="width: 30px; height: 30px;" />
        </b-col>
        <b-col sm="12" lg="11">
          <div style="padding-top: 5px;">{{ StoreLoading.data.label }}</div>
          <template v-if="d_progressData">
            <b-progress :value="d_progressData.percent" variant="success" show-progress striped :animated="true" class="mb-2"></b-progress>
            <div style="padding-top: 5px;">
              <span v-if="d_progressData.total_process_count"> Toplam İşlem: {{ d_progressData.total_process_count }} </span>
              <span v-if="d_progressData.process_count">İşlem Adedi: {{ d_progressData.process_count }} </span>
            </div>
            <div v-if="d_progressData.message" style="padding-top: 5px;"> Mesaj: {{ d_progressData.message }} </div>
          </template>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="store-modal" v-model="d_showStoreModal" :title="StoreModal.data.title ? StoreModal.data.title : 'Uyarı'" :centered="StoreModal.data.centered ? StoreModal.data.centered : true" :header-bg-variant="StoreModal.data.header_bg_variant ? StoreModal.data.header_bg_variant : 'info'" :header-text-variant="StoreModal.data.header_text_variant ? StoreModal.data.header_text_variant : 'dark'">
      <div style="white-space: pre-line;" v-if="StoreModal.data.text">{{ StoreModal.data.text }}</div>
      <template slot="modal-footer">
        <b-button v-if="StoreModal.data.type === 'alert'" @click="d_showStoreModal = false">ok</b-button>
        <b-button v-if="StoreModal.data.type === 'confirm'" @click="d_showStoreModal = false">cancel</b-button>
        <b-button v-if="StoreModal.data.type === 'confirm'" @click="f_confirmStoreModal()">İşlemi gerçekleştir</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { data as lang_list } from '@/options/lang_list';
import auth from '@/auth';
import moment from "moment";
import { mapGetters } from 'vuex';
import { version } from '@/config';
import UserShopService from '@/services/user_shop';
import {
  default as Modal
} from '@/components/widgets/Modal';
// import {default as WisdomSocket } from '@/components/socket';

export default {
  name: 'DefaultContainer',
  components: {
    Modal,
    // WisdomSocket
  },
  computed: {
    ...mapGetters({
      StoreDevice: 'StoreDevice',
      StoreUser: 'StoreUser',
      StoreLoading: 'StoreLoading',
      StoreModal: 'StoreModal',
      StoreLang: 'StoreLang',
      StoreCurrencyType: 'StoreCurrencyType',
      StoreCurrencyTypeList: 'StoreCurrencyTypeList',
      StoreFrontendSettings: 'StoreFrontendSettings',
      StoreFrontendWebsiteunit: 'StoreFrontendWebsiteunit',
      StoreMenuData: 'StoreMenuData',
      StoreLangList: 'StoreLangList',
      StoreLangTranslation: 'StoreLangTranslation',
      StoreShopCartList: 'StoreShopCartList',
      StoreShopCartOrderList: 'StoreShopCartOrderList',
    })
  },
  data () {
    return {
      d_selectedParentMenuItemIndex: '',
      d_flowingTextInterval: 0,
      d_allFlowingText: {
        'list': [],
        'list_index': 0,
        'text': '',
        'index': 0,
        'wait': 0
      },
      d_topShowMenuIndexList: [],
      d_selectedLang: 'en',
      d_selectedCurrencyType: {'value': '0', 'label': '₺'},
      d_notShowBarRoutes: [],
      d_progressData: '',
      d_showAplicationFooter: true,
      d_showAplicationHeader: true,
      d_showAplicationSidebar: false,
      d_showLoadingModal: false,
      d_showStoreModal: false,
      d_user: '',
      d_version: version,
    }
  },
  destroyed () {
    clearInterval(this.d_flowingTextInterval);
    // clearInterval(this.d_nextDrawAlgorithm);
  },
  created: function () {
    if (this.StoreMenuData.data.list) {
      for (let i in this.StoreMenuData.data.list) {
        if (this.StoreMenuData.data.list[i].show_in_menu.value === '1') {
          if (this.d_topShowMenuIndexList.length < 7) {
            this.d_topShowMenuIndexList.push(parseInt(i));
          }
        }
      }
    }
    if (localStorage.getItem('lang')){
      this.d_selectedLang = localStorage.getItem('lang');
    }
    if (localStorage.getItem('user_currency_type')){
      this.d_selectedCurrencyType = JSON.parse(localStorage.getItem('user_currency_type'));
    }
    // console.log(this.StoreLangTranslation);
    // console.log(this.StoreLangList);
    // console.log(this.StoreMenuData);
    // console.log(this.StoreFrontendWebsiteunit);
    // console.log(this.StoreFrontendSettings);
    // console.log(this.StoreUser);
    // console.log('StoreDevice.isMobile : ', StoreDevice.isMobile);
    this.d_user = JSON.parse(localStorage.getItem('user'));
    if (this.$route.meta.iframe_mode === 'yes') {
      // console.log(this.$route.meta.iframe_mode);
      this.d_showAplicationSidebar = false;
      this.d_showAplicationHeader = false;
      this.d_showAplicationFooter = false;
    } else {
      if (this.StoreDevice && this.StoreDevice.isMobile) {
        this.d_showAplicationSidebar = false;
      } else {
        if (this.StoreFrontendSettings.data.menu_type === '0') {
          this.d_showAplicationSidebar = false;
        } else if (this.StoreFrontendSettings.data.menu_type === '1') {
          this.d_showAplicationSidebar = false;
        } else if (this.StoreFrontendSettings.data.menu_type === '2') {
          this.d_showAplicationSidebar = true;
        }
        if (this.StoreFrontendSettings.data.navbar_flowing_text && this.StoreFrontendSettings.data.navbar_flowing_text[this.d_selectedLang]) {
          this.d_allFlowingText.list.push({'label': this.StoreFrontendSettings.data.navbar_flowing_text[this.d_selectedLang]});
          this.f_startFlowingText();
        }
      }
    }
    if (this.d_user && this.d_user.username) {
      this.f_userShopCartBundleList();
      this.f_userShopCartBundleOrderList();
    }
  },
  destroyed () {},
  beforeCreate: function () {
  },
  mounted: function () {
    if (this.StoreLoading.status) {
      this.d_showLoadingModal = true;
    }
  },
  methods: {
    f_menuMouseOver: function (menu_item_index) {
      // console.log('f_menuMouseOver ', menu_item_index);
      this.$refs['menu_item__' + menu_item_index][0].show();
      this.d_selectedParentMenuItemIndex = menu_item_index;
      // console.log(this.$refs['menu_item__' + menu_item_index][0]);
      // this.$refs['menu_item' + menu_item_index].showMenu = true;
    },
    f_selectLang: function (lang) {
      this.d_selectedLang = lang.label;
      localStorage.setItem('lang', this.d_selectedLang);
      localStorage.setItem('active_lang', JSON.stringify(lang));
      if (this.StoreFrontendSettings.data.navbar_flowing_text && this.StoreFrontendSettings.data.navbar_flowing_text[this.d_selectedLang]) {
        this.d_allFlowingText.list = [];
        this.d_allFlowingText.list.push({'label': this.StoreFrontendSettings.data.navbar_flowing_text[this.d_selectedLang]});
        clearInterval(this.d_flowingTextInterval);
        this.f_startFlowingText();
      }
    },
    f_startFlowingText: function () {
      // when we start, we defined the list index we are flowing on.
      if (this.d_allFlowingText.list.length > 0) {
        this.d_allFlowingText.text = this.d_allFlowingText.list[0].label;
        this.d_allFlowingText.list_index = 0;
        this.d_flowingTextInterval = setInterval(function () {
          if (this.d_allFlowingText.index !== this.d_allFlowingText.text.length) {
            this.d_allFlowingText.index += 1;
          } else {
            // 30 defa wait olduktan sonra tüm text yeniden başa sarıyor.
            if (this.d_allFlowingText.wait === 30) {
              this.d_allFlowingText.wait = 0;
              this.d_allFlowingText.index = 0;
              if (this.d_allFlowingText.list_index !== this.d_allFlowingText.list.length - 1) {
                this.d_allFlowingText.list_index += 1;
                this.d_allFlowingText.text = this.d_allFlowingText.list[this.d_allFlowingText.list_index].label;
              } else {
                this.d_allFlowingText.list_index = 0;
                this.d_allFlowingText.text = this.d_allFlowingText.list[0].label;
              }
            } else {
              this.d_allFlowingText.wait += 1;
            }
          }
        }.bind(this), 100);
      }
    },
    f_calculateFooterBelowStyle: function () {
      let style = 'padding-top: 100px; padding-bottom: 100px; font-family: roboto;';
      let footer_background_color = 'whitesmoke';
      if (this.StoreFrontendSettings.data.footer_background_color) {
        footer_background_color = this.StoreFrontendSettings.data.footer_background_color;
      }
      style += 'background-color: ' + footer_background_color + ';';

      let footer_text_color = 'rgb(36, 80, 90)';
      if (this.StoreFrontendSettings.data.footer_text_color) {
        footer_text_color = this.StoreFrontendSettings.data.footer_text_color;
      }
      style += 'color: ' + footer_text_color + ';';
      return style;
    },
    f_calculateFooterStyle: function () {
      let font_family = 'Inter';
      let style = 'padding-top: 100px; font-family: ' + font_family + '; font-weight: 400;';
      let footer_background_color = 'whitesmoke';
      if (this.StoreFrontendSettings.data.footer_background_color) {
        footer_background_color = this.StoreFrontendSettings.data.footer_background_color;
      }
      style += 'background-color: ' + footer_background_color + ';';

      let footer_text_color = 'rgb(36, 80, 90)';
      if (this.StoreFrontendSettings.data.footer_text_color) {
        footer_text_color = this.StoreFrontendSettings.data.footer_text_color;
      }
      style += 'color: ' + footer_text_color + ';';
      return style;
    },
    f_calculateAppContainerStyle: function () {
      let font_family = 'Inter';
      let style = 'font-family: ' + font_family + '; font-weight: 400;';
      let page_area_text_color = 'rgb(36, 80, 90)';
      if (this.StoreFrontendSettings.data.page_area_text_color) {
        page_area_text_color = this.StoreFrontendSettings.data.page_area_text_color;
      }
      style += 'color: ' + page_area_text_color + ';';
      return style;
    },
    f_calculateMenuCaptionStyle: function (menu_type, menu_item_index='') {
      let style = '';
      let text_color = 'rgb(36, 80, 90)';
      // console.log(this.StoreFrontendSettings.data.navbar_background_color);
      if (menu_type === 'parent') {
        style += 'font-size: 20px;';
        style += 'font-weight: 500;';
        if (this.StoreFrontendSettings.data.menu_parent_item_text_color) {
          text_color = this.StoreFrontendSettings.data.menu_parent_item_text_color;
        }
      } else if (menu_type === 'child') {
        style += 'font-size: 18px;';
        style += 'font-weight: 300;';
        // style += 'margin-left: 10px;';
        if (this.StoreFrontendSettings.data.menu_child_item_text_color) {
          text_color = this.StoreFrontendSettings.data.menu_child_item_text_color;
        }
      }
      style += 'color: ' + text_color + ';';
      if (this.d_selectedParentMenuItemIndex !== '' && menu_item_index === this.d_selectedParentMenuItemIndex) {
        style += 'border-bottom: solid 5px ' + text_color + ';';
      }
      return style;
    },
    f_navBarTopMenuStyle: function () {
      let style = '';
      let box_shadow_color = '2px 1px 2px 1px #c5ded9;';
      // console.log(this.StoreFrontendSettings.data.navbar_background_color);
      if (this.StoreFrontendSettings.data.navbar_background_color) {
        box_shadow_color = '2px 1px 2px 1px ' + this.StoreFrontendSettings.data.navbar_background_color;
      }
      // dashboard kullanılmayacağından total_main_menu_count değerini 0 ile saymaya başlıyoruz.
      let total_main_menu_count = 0;
      let h = 115;
      for (let menu_item_index in this.StoreMenuData.data.list) {
        let menu_item = this.StoreMenuData.data.list[menu_item_index];
        let check_menu_item = this.f_checkMenuPermission(menu_item);
        // console.log('check_menu_item: ', check_menu_item);
        if (this.StoreMenuData.data.list[menu_item_index].show_in_menu.value === '1' && check_menu_item) {
          total_main_menu_count += 1;
        }
      }
      // her 5 adet ana menü için yükseklik düzenlemesi yapılıyor.
      if (total_main_menu_count <= 5) {
        h = 115;
      } else if (total_main_menu_count > 5 && total_main_menu_count <= 10) {
        h = 115 + 50;
      } else if (total_main_menu_count > 10 && total_main_menu_count <= 15) {
        h = 115 + 50 + 50;
      }
      // console.log('total_main_menu_count: ', total_main_menu_count);
      style += 'height: ' + h + 'px;';
      style += 'box-shadow: ' + box_shadow_color + ';';
      style += 'background-color: white;';
      style += 'z-index: 1000;';
      style += 'position: fixed;';
      style += 'width: 100%;';
      return style;
    },
    f_calculateNavbarStyle: function () {
      let style = 'margin: 0px; height: 40px; font-size: 15px;';
      let navbar_background_color = 'rgb(39 176 178)';
      if (this.StoreFrontendSettings.data.navbar_background_color) {
        navbar_background_color = this.StoreFrontendSettings.data.navbar_background_color;
      }
      style += "background-color: " + navbar_background_color + ";"

      let navbar_text_color = 'white';
      if (this.StoreFrontendSettings.data.navbar_text_color) {
        navbar_text_color = this.StoreFrontendSettings.data.navbar_text_color;
      }
      style += "color: " + navbar_text_color + ";"
      return style;
    },
    f_calculateShopcartButtonStyle: function () {
      let style = '';
      let navbar_text_color = 'white';
      if (this.StoreFrontendSettings.data.navbar_text_color) {
        navbar_text_color = this.StoreFrontendSettings.data.navbar_text_color;
      }
      style += "color: " + navbar_text_color + ";"
      return style;
    },
    f_calculateSelectedLangStyle: function () {
      let style = 'font-size: 15px;';
      let navbar_text_color = 'white';
      if (this.StoreFrontendSettings.data.navbar_text_color) {
        navbar_text_color = this.StoreFrontendSettings.data.navbar_text_color;
      }
      style += "color: " + navbar_text_color + ";"
      return style;
    },
    f_calculateSelectedCurrencyStyle: function () {
      let style = 'font-size: 15px;';
      let navbar_text_color = 'white';
      if (this.StoreFrontendSettings.data.navbar_text_color) {
        navbar_text_color = this.StoreFrontendSettings.data.navbar_text_color;
      }
      style += "color: " + navbar_text_color + ";"
      return style;
    },
    f_calculateUsernameShowStyle: function () {
      let style = 'padding: 3px; font-size: 15px;';
      let navbar_text_color = 'white';
      if (this.StoreFrontendSettings.data.navbar_text_color) {
        navbar_text_color = this.StoreFrontendSettings.data.navbar_text_color;
      }
      style += "color: " + navbar_text_color + ";"
      return style;
    },
    f_clickMainArea: function () {
      if (this.StoreFrontendSettings.data.menu_type === '0') {
        //
      } else if (this.StoreFrontendSettings.data.menu_type === '1') {
        this.d_showAplicationSidebar = false;
      } else if (this.StoreFrontendSettings.data.menu_type === '2') {
        //
      }
    },
    f_menuAreaClick: function () {
      // console.log('this.StoreDevice.isMobile ', this.StoreDevice.isMobile);
      if (this.StoreDevice.isMobile) {
        this.d_showAplicationSidebar = false;
      } else {
        if (this.StoreFrontendSettings.data.menu_type === '0') {
          //
        } else if (this.StoreFrontendSettings.data.menu_type === '1') {
          this.d_showAplicationSidebar = false;
        } else if (this.StoreFrontendSettings.data.menu_type === '2') {
          //
        }
      }
    },
    f_createThisRouteHrefLink: function (route_id) {
      let route_menu_item = '';
      for (let i in this.StoreMenuData.data.list) {
        if (this.StoreMenuData.data.list[i].meta.id === route_id) {
          route_menu_item = this.StoreMenuData.data.list[i];
          break
        }
        if (this.StoreMenuData.data.list[i].list && this.StoreMenuData.data.list[i].list.length > 0) {
          for (let sub_i in this.StoreMenuData.data.list[i].list) {
            if (this.StoreMenuData.data.list[i].list[sub_i].meta.id === route_id) {
              route_menu_item = this.StoreMenuData.data.list[i].list[sub_i];
              break
            }
          }
        }
        if (route_menu_item !== '') {
          break
        }
      }
      // console.log('route_menu_item :', route_menu_item);
      let href_link = this.f_createHrefLink(route_menu_item);
      // console.log('href_link : ', href_link);
      return href_link;
    },
    f_createHrefLink: function (menu_item) {
      let route_path = '';
      let href_url = '';
      try {
        route_path = menu_item.route_path[this.StoreLang];
      } catch (err) {}
      // console.log('route_path : ', route_path);
      if (route_path) {
        if (route_path[0] === '/') {
          href_url = window.location.origin + '/#' + route_path;
        } else {
          href_url = window.location.origin + '/#/' + route_path;
        }
      } else {
        href_url = window.location.origin;
      }
      return href_url;
    },
    f_selectCurrencyType: function (cur_type) {
      this.d_selectedCurrencyType.value = cur_type.value;
      this.d_selectedCurrencyType.label = cur_type.label;
      localStorage.setItem('user_currency_type', JSON.stringify(cur_type));
    },
    f_getRedirectDashboardRoute: function () {
      let redirect_dashboard_path = '';
      for (let lang_index in lang_list) {
        let lang_label = lang_list[lang_index]['label'];
        for (let i in this.StoreMenuData.data.list) {
          let is_dashboard = '';
          try {
            is_dashboard = this.StoreMenuData.data.list[i].is_dashboard['value'];
          } catch (err) {}
          if (is_dashboard === '1') {
            let route_path = '';
            try {
              route_path = this.StoreMenuData.data.list[i].route_path[lang_label];
            } catch (err) {}
            if (route_path) {
              redirect_dashboard_path = route_path;
              break;
            }
          }
          if (this.StoreMenuData.data.list[i].list && this.StoreMenuData.data.list[i].list.length > 0) {
            for (let sub_i in this.StoreMenuData.data.list[i].list) {
              let is_dashboard = '';
              try {
                is_dashboard = this.StoreMenuData.data.list[i].list[sub_i].is_dashboard['value'];
              } catch (err) {}
              if (is_dashboard === '1') {
                let route_path = '';
                try {
                  route_path = this.StoreMenuData.data.list[i].list[sub_i].route_path[lang_label];
                } catch (err) {}
                if (route_path) {
                  redirect_dashboard_path = route_path;
                  break;
                }
              }

            }
          }
        }
      }
      // console.log('this.StoreMenuData ', this.StoreMenuData);
      // console.log('redirect_dashboard_path : ', redirect_dashboard_path);
      return redirect_dashboard_path;
    },
    f_checkLoginMenuItem: function (menu_item) {
      // are_shop_pages_active
      // tüm izinlerin destekleyeceği şekilde kontrol yapılıyor. Yani and mantığında.
      // console.log(menu_item.meta.login);
      let login_menu_item = true;
      if (menu_item.meta && menu_item.meta.login && menu_item.meta.login[this.StoreLang] === 'yes') {
        if (this.d_user && this.d_user.username) {
          //
        } else {
          login_menu_item = false;
        }
      }
      return login_menu_item;
    },
    f_checkMenuPermission: function (menu_item) {
      // are_shop_pages_active
      // tüm izinlerin destekleyeceği şekilde kontrol yapılıyor. Yani and mantığında.
      // console.log(menu_item.meta.login);
      let show_menu = true;
      if (menu_item.meta && menu_item.meta.login && menu_item.meta.login[this.StoreLang] === 'yes') {
        /*
          if (!this.d_user) {
            show_menu = false;
          }
        */
      }
      if (menu_item.meta && menu_item.meta.permission && menu_item.meta.permission[this.StoreLang].length > 0) {
        if (this.d_user && this.d_user.auth && this.d_user.auth.length > 0) {
          let det = false;
          for (let perm_index in menu_item.meta.permission[this.StoreLang]) {
            if (this.d_user.auth.indexOf(menu_item.meta.permission[this.StoreLang][perm_index]) !== -1) {
              det = true;
              break;
            }
          }
          if (!det) {
            show_menu = false;
          }
        } else {
          show_menu = false;
        }
      }
      if (show_menu) {
        if (menu_item.meta && menu_item.meta.shop_active && menu_item.meta.shop_active[this.StoreLang]) {
          if (menu_item.meta.shop_active[this.StoreLang] === 'yes') {
            if (this.StoreFrontendSettings.data.are_shop_pages_active === '0') {
              show_menu = false;
            }
          }
        }
      }
      return show_menu;
    },
    f_styleNavbarApplicationName: function () {
      let style = '';
      style += 'cursor: pointer;';
      style += 'text-shadow: -1px -1px ' + this.StoreFrontendSettings.data.navbar_application_name_shadow_color + ';';
      style += 'font-size: 20px;';
      return style;
    },
    f_styleNavbarApplicationNameTopMenu: function () {
      let style = '';
      let application_name_color = 'rgb(14 132 134)';
      if (this.StoreFrontendSettings.data.navbar_application_name_color) {
        application_name_color = this.StoreFrontendSettings.data.navbar_application_name_color;
      }
      style += 'cursor: pointer;';
      style += 'text-shadow: -1px -1px ' + this.StoreFrontendSettings.data.navbar_application_name_shadow_color + ';';
      style += 'font-size: 22px;';
      style += 'color: ' + application_name_color + ' ;';
      style += 'font-weight: 700;';
      return style;
    },
    f_goToMainPage: function () {
      let redirect_dashboard_path = this.f_getRedirectDashboardRoute();
      // console.log('redirect_dashboard_path : ', redirect_dashboard_path);
      this.$router.push({ path: '/' + redirect_dashboard_path, query: {} });
      this.d_showAplicationSidebar = false;
    },
    f_logout: function () {
      this.$router.push({ 'path': '/logout', 'query': {} });
    },
    f_login: function () {
      this.$router.push({ 'path': '/login', 'query': {} });
    },
    f_calculateMenuItemStyle: function (menu_type, menu_item) {
      let route_name = '';
      try {
        route_name = menu_item.route_name[this.StoreLang];
      } catch (err) {}
      let style = '';
      // console.log('route_name       : ', route_name);
      // console.log('this.$route.name : ', this.$route.name);
      if (menu_type === 'parent') {
        style += 'color: ' + this.StoreFrontendSettings.data.menu_parent_item_text_color + ';';
        style += 'cursor: pointer;';
        style += 'min-height: 40px;';
        style += 'font-size: 17px;';
        style += 'margin: 0px;';
        style += 'padding: 5px;';
        style += 'border-bottom: solid 0.01em ' + this.StoreFrontendSettings.data.menu_parent_item_border_bottom_color + ';';
        // style += 'text-shadow: -1px -1px rgb(0, 0, 0);';
        if (this.$route.name === route_name) {
          // style = 'background-color: rgb(12, 147, 65);';
          style += 'background-color: ' + this.StoreFrontendSettings.data.menu_parent_item_background_color + ';';
        }
      } else if (menu_type === 'child') {
        style += 'color: ' + this.StoreFrontendSettings.data.menu_child_item_text_color + ';';
        style += 'cursor: pointer;';
        style += 'font-size: 15px;';
        style += 'padding: 5px;';
        style += 'min-height: 30px;';
        style += 'margin: 0px;';
        style += 'margin-left: 10px;';
        style += 'border-bottom: solid 0.01em ' + this.StoreFrontendSettings.data.menu_child_item_border_bottom_color + ';';
        // style += 'text-shadow: -1px -1px rgb(0, 0, 0);';
        if (this.$route.name === route_name) {
          // style = 'background-color: rgb(12, 147, 65);';
          style += 'background-color: ' + this.StoreFrontendSettings.data.menu_child_item_background_color + ';';
        }
      }
      return style;
    },
    f_calculateTopMenuItemStyle: function (menu_type, menu_item) {
      let route_name = '';
      try {
        route_name = menu_item.route_name[this.StoreLang];
      } catch (err) {}
      let style = '';
      // console.log('route_name       : ', route_name);
      // console.log('this.$route.name : ', this.$route.name);
      // 'cursor: pointer; border-bottom: solid 3px #05b4c5; margin: 5px; background-color: #e2f4f9;' :'cursor: pointer; margin: 5px;'
      if (menu_type === 'parent') {
        style += 'color: ' + this.StoreFrontendSettings.data.menu_parent_item_text_color + ';';
        style += 'cursor: pointer;';
        style += 'min-height: 30px;';
        style += 'margin: 10px;';
        if (this.$route.name === route_name) {
          style += 'border-bottom: solid 0.01em ' + this.StoreFrontendSettings.data.menu_parent_item_border_bottom_color + ';';
          // style += 'background-color: ' + this.StoreFrontendSettings.data.menu_parent_item_background_color + ';';
        }
      } else if (menu_type === 'child') {
        style += 'color: ' + this.StoreFrontendSettings.data.menu_child_item_text_color + ';';
        style += 'cursor: pointer;';
        style += 'font-size: 12px;';
        style += 'min-height: 30px;';
        style += 'margin: 0px;';
        if (this.$route.name === route_name) {
          style += 'border-bottom: solid 0.01em ' + this.StoreFrontendSettings.data.menu_child_item_border_bottom_color + ';';
          // style += 'background-color: ' + this.StoreFrontendSettings.data.menu_child_item_background_color + ';';
        }
      }
      return style;
    },
    f_calculateMainAreaStyle: function () {
      /*
        font-family: "Roboto";
        font-weight: 300;
        font-size: 24px;
      */
      let font_family = 'Inter';
      let style = '';
      let page_area_text_color = 'rgb(36, 80, 90)';
      if (this.StoreFrontendSettings.data.page_area_text_color) {
        page_area_text_color = this.StoreFrontendSettings.data.page_area_text_color;
      }
      if (this.StoreFrontendSettings.data.menu_type === '0') {
        // sol menü kapalı modda.
        if (this.d_showAplicationSidebar) {
          if (this.StoreDevice && this.StoreDevice.isMobile) {
            style = 'padding: 10px; margin-left: 0px; width: 100%; background-color: white; font-family: ' + font_family + '; min-height: 750px; font-weight: 300; font-size: 20px;';
            style += 'color: ' + page_area_text_color + ';';
          } else {
            style = 'padding: 10px; margin-left: 0px; width: 100%; background-color: white; font-family: ' + font_family + '; min-height: 750px; font-weight: 300; font-size: 20px;';
            style += 'color: ' + page_area_text_color + ';';
            // style = 'font-family: fantasy; padding: 10px; margin-left: 325px; width: ' + (window.innerWidth - 325).toString() + 'px;';
          }
        } else {
          if (this.StoreDevice && this.StoreDevice.isMobile) {
            style = 'padding: 10px; margin-left: 0px; width: 100%; background-color: white; font-family: ' + font_family + '; min-height: 750px; font-weight: 300; font-size: 20px;';
            style += 'color: ' + page_area_text_color + ';';
          } else {
            style = 'padding: 10px; margin-left: 0px; width: 100%; background-color: white; font-family: ' + font_family + '; min-height: 750px; font-weight: 300; font-size: 20px;';
            style += 'color: ' + page_area_text_color + ';';
          }
        }
      } else if (this.StoreFrontendSettings.data.menu_type === '1') {
        // üst menü modu.
        if (this.d_showAplicationSidebar) {
          if (this.StoreDevice && this.StoreDevice.isMobile) {
            style = 'padding: 10px; margin-left: 0px; width: 100%; background-color: white; font-family: ' + font_family + '; min-height: 750px; font-weight: 300; font-size: 20px;';
            style += 'color: ' + page_area_text_color + ';';
          } else {
            style = 'padding: 10px; margin-left: 0px; width: 100%; background-color: white; font-family: ' + font_family + '; min-height: 750px; font-weight: 300; font-size: 20px;';
            style += 'color: ' + page_area_text_color + ';';
            // style = 'font-family: fantasy; padding: 10px; margin-left: 325px; width: ' + (window.innerWidth - 325).toString() + 'px;';
          }
        } else {
          if (this.StoreDevice && this.StoreDevice.isMobile) {
            style = 'padding: 10px; margin-left: 0px; width: 100%; background-color: white; font-family: ' + font_family + '; min-height: 750px; font-weight: 300; font-size: 20px;';
            style += 'color: ' + page_area_text_color + ';';
          } else {
            style = 'padding: 10px; margin-left: 0px; width: 100%; background-color: white; font-family: ' + font_family + '; min-height: 750px; font-weight: 300; font-size: 20px;';
            style += 'color: ' + page_area_text_color + ';';
          }
        }
      } else if (this.StoreFrontendSettings.data.menu_type === '2') {
        // sol menü modu açık.
        if (this.d_showAplicationSidebar) {
          if (this.StoreDevice && this.StoreDevice.isMobile) {
            style = 'padding: 10px; margin-left: 330px; background-color: white; font-family: ' + font_family + '; min-height: 750px; font-weight: 300; font-size: 20px;';
            style += 'color: ' + page_area_text_color + ';';
          } else {
            style = 'padding: 10px; margin-left: 330px; background-color: white; font-family: ' + font_family + '; min-height: 750px; font-weight: 300; font-size: 20px;';
            style += 'color: ' + page_area_text_color + ';';
            // style = 'font-family: fantasy; padding: 10px; margin-left: 325px; width: ' + (window.innerWidth - 325).toString() + 'px;';
          }
        } else {
          if (this.StoreDevice && this.StoreDevice.isMobile) {
            style = 'padding: 10px; margin-left: 0px; width: 100%; background-color: white; font-family: ' + font_family + '; min-height: 750px; font-weight: 300; font-size: 20px;';
            style += 'color: ' + page_area_text_color + ';';
          } else {
            style = 'padding: 10px; margin-left: 0px; width: 100%; background-color: white; font-family: ' + font_family + '; min-height: 750px; font-weight: 300; font-size: 20px;';
            style += 'color: ' + page_area_text_color + ';';
          }
        }
      }

      if (this.StoreDevice && this.StoreDevice.isMobile) {
        style += 'margin-top: 50px;';
      } else {
        if (['0', '2'].indexOf(this.StoreFrontendSettings.data.menu_type) !== -1) {
            style += 'margin-top: 50px;';
          } else if (this.StoreFrontendSettings.data.menu_type === '1') {
            style += 'margin-top: 115px;';
          }
      }

      return style;
    },
    f_sideBarStyle: function () {
      let style = 'height: ' + (window.innerHeight - 50).toString() + 'px;';
      style += 'background-color: ' + this.StoreFrontendSettings.data.menu_background_color + ';';
      // style += 'background: linear-gradient(233deg, rgb(1 214 168) 5%, rgb(1 194 152) 10%, rgb(0 165 129) 30%, rgb(1 145 114) 50%, rgb(1 126 99) 70%, rgb(0 127 99) 80%, #00886a 95% )'
      style += 'border-bottom: solid 1px ' + this.StoreFrontendSettings.data.menu_border_bottom_color + ';';
      style += 'box-shadow: 2px 1px 2px 1px ' + this.StoreFrontendSettings.data.menu_box_shadow_color + ';';
      style += 'margin-top: 3px;';
      style += 'margin: 0px;';
      style += 'overflow-x: hidden;';
      style += 'overflow-y: auto;';
      style += 'position: fixed;';
      style += 'top: 50px;';
      style += 'width: 325px;';
      style += 'z-index: 1000;';
      // style += '::-webkit-scrollbar-track: {border-radius: 5px; background-color: rgba(170, 70, 12, 0.1);}';
      // style += '::-webkit-scrollbar: {width: 10px; background-color: rgba(170, 70, 12, 0.1);};';
      // style += '::-webkit-scrollbar-thumb: {border-radius: 100px; -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3); background-color: rgba(170, 70, 12, 0.5);}';
      return style;
    },
    f_goToContactUsPage: function () {
      this.$router.push({ 'path': '/contact', 'query': {} });
    },
    f_goToThisPage: function (menu_item) {
      // console.log('menu_item : ', menu_item);
      let is_login = '0';
      let route_path = '';
      try {
        route_path = menu_item.route_path[this.StoreLang];
      } catch (err) {}

      if (route_path) {
        if (route_path[0] !== '/') {
          route_path = '/' + route_path;
        }
        // console.log('route_path : ', route_path);
        if (this.d_user && this.d_user.username) {
          if (this.$route.path !== route_path) {
            this.$router.push({ 'path': route_path, 'query': {} });
          }
        } else {
          if (is_login === '1') {
            this.$router.push({ 'path': '/login', 'query': {} });
          } else {
            if (this.$route.path !== route_path) {
              this.$router.push({ 'path': route_path, 'query': {} });
            }
          }
        }
        if (this.StoreFrontendSettings.data.menu_type === '0') {
          //
        } else if (this.StoreFrontendSettings.data.menu_type === '1') {
          this.d_showAplicationSidebar = false;
        } else if (this.StoreFrontendSettings.data.menu_type === '2') {
          //
        }
      }
    },
    f_goToThisUrl: function (url) {
      window.open(url, '_blank');
      if (this.StoreDevice && this.StoreDevice.isMobile) {
        this.d_showAplicationSidebar = false;
      }
    },
    f_confirmStoreModal: function () {
      let data = JSON.parse(JSON.stringify(this.StoreModal.data));
      data.confirm = true;
      this.$store.commit('MutationModal', data);
      this.d_showStoreModal = false;
    },
    f_showProgressBarByInterval: function () {
      this.showProgresBarInterval = setInterval(() => {
        if (!this.d_progressData) {
          this.d_progressData = {};
        }
        if (this.d_progressData.percent === undefined) {
          this.d_progressData.percent = 5;
        } else {
          if (this.d_progressData.percent <= 50) {
            this.d_progressData.percent += 4;
          } else if (this.d_progressData.percent > 50 && this.d_progressData.percent <= 75) {
            this.d_progressData.percent += 3;
          } else if (this.d_progressData.percent > 75 && this.d_progressData.percent <= 85) {
            this.d_progressData.percent += 2;
          } else if (this.d_progressData.percent > 85 && this.d_progressData.percent < 99) {
            this.d_progressData.percent += 1;
          }
        }
        this.$forceUpdate();
      }, 500);
    },
    f_showUserName: function () {
      let result_name = '';
      if (this.d_user && this.d_user.first_name) {
        let result_name_list = [];
        let first_name = this.d_user.first_name;
        let last_name = this.d_user.last_name;
        let first_name_list = first_name.split(' ');
        for (let i in first_name_list) {
          if (parseInt(i) + 1 === first_name_list.length) {
            result_name_list.push(first_name_list[i]);
          } else {
            result_name_list.push(first_name_list[i][0]);
          }
        }
        let last_name_list = last_name.split(' ');
        for (let i in last_name_list) {
          if (parseInt(i) + 1 === last_name_list.length) {
            result_name_list.push(last_name_list[i]);
          } else {
            result_name_list.push(last_name_list[i][0]);
          }
        }
        result_name = result_name_list.join(' ');
      }
      return result_name;
    },
    f_userShopCartBundleList: function () {
      let query = 'user_client_id=' + this.d_user.username;
      let data = {};
      UserShopService.user_shopcart_bundle_list(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            // alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            // we dont want to change StoreShopCartList.status. Because for every change, watch is triggered to trigger this function!!!
            // console.log('this.StoreShopCartList before mutation => ', this.StoreShopCartList);
            this.$store.commit('MutationShopCartList', { 'status': this.StoreShopCartList.status, 'data': resp.data.list });
            // console.log('this.StoreShopCartList after mutation => ', this.StoreShopCartList);
          } else {
            alert(resp.data.status_code, ' ', resp.data.status_message);
          }
        });
    },
    f_calculateBundleOrderCount: function () {
      let total_count = 0;
      for (let i in this.StoreShopCartOrderList.data) {
        total_count += this.StoreShopCartOrderList.data[i].bundle_list.length;
      }
      return total_count;
    },
    f_calculateShopcartBundleCount: function () {
      let total_count = 0;
      for (let i in this.StoreShopCartList.data) {
        total_count += this.StoreShopCartList.data[i].bundle_list.length;
      }
      return total_count;
    },
    f_userShopCartBundleOrderList: function () {
      let query = 'user_client_id=' + this.d_user.username;
      let data = {};
      UserShopService.user_shopcart_bundle_order_list(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            // alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            // we dont want to change StoreShopCartOrderList.status. Because for every change, watch is triggered to trigger this function!!!
            // console.log('this.StoreShopCartOrderList before mutation => ', this.StoreShopCartOrderList);
            this.$store.commit('MutationShopCartOrderList', { 'status': this.StoreShopCartOrderList.status, 'data': resp.data.list });
            // console.log('this.StoreShopCartOrderList after mutation => ', this.StoreShopCartOrderList);
          } else {
            alert(resp.data.status_code, ' ', resp.data.status_message);
          }
        });
    },
  },
  watch: {
    'd_showStoreModal': function () {
      if (!this.d_showStoreModal) {
        this.$store.commit('MutationModalShow', false);
      }
    },
    'StoreDevice.isMobile': function () {
      console.log('this.StoreDevice.isMobile : ', this.StoreDevice.isMobile);
    },
    'd_selectedLang': function () {
      this.$store.commit('MutationLang', this.d_selectedLang);
    },
    'd_selectedCurrencyType.value': function () {
      this.$store.commit('MutationCurrencyType', this.d_selectedCurrencyType);
    },
    'StoreUser.status': function () {
      console.log('this.StoreUser : ', this.StoreUser.status);
    },
    'StoreShopCartList.status': function () {
      // console.log('this.StoreShopCartList.status : ', this.StoreShopCartList.status);
      // console.log('this.StoreShopCartList : ', this.StoreShopCartList);
      this.f_userShopCartBundleList();
    },
    'StoreShopCartOrderList.status': function () {
      // console.log('this.StoreShopCartOrderList.status : ', this.StoreShopCartOrderList.status);
      // console.log('this.StoreShopCartOrderList : ', this.StoreShopCartOrderList);
      this.f_userShopCartBundleOrderList();
    },
    'StoreLoading.status': function () {
      if (this.StoreLoading.status) {
        this.d_showLoadingModal = true;
        this.d_progressData = '';
        this.f_showProgressBarByInterval();
      } else {
        this.d_showLoadingModal = false;
        this.d_progressData = '';
        clearInterval(this.showProgresBarInterval);
      }
    },
    '$route.name': function () {
      let app_title = document.getElementById('app_title');
      // console.log('this.$route : ', this.$route);
      let app_name = '';
      try {
        app_name = this.StoreFrontendSettings.data.application_name[this.StoreLang];
      } catch(err) {}
      if (app_name) {
        app_title.innerHTML = app_name + ' | ' + this.$route.path;
      } else {
        app_title.innerHTML = this.$route.path;
      }
      app_title.href = this.StoreFrontendSettings.data.navbar_middle_logo;
    },
    'StoreModal.show': function () {
      if (this.StoreModal.show) {
        this.d_showStoreModal = true;
      } else {
        this.d_showStoreModal = false;
        if (this.StoreModal.data.function_name) {
          if (this.StoreModal.data.function_name === 'f_exampleMethod' && this.StoreModal.data.confirm) {
            // this.f_exampleMethod();
          }
        }
      }
    }
  }
}

</script>

<style type="text/css">



</style>

